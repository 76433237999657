import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VForm,{ref:"CabAccountForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VCardTitle,[_vm._v("Account For cab Request")]),_c(VCardText,[_c('div',{staticClass:"mx-5 mt-5"},[_c(VTextField,{attrs:{"label":"Trip Cost","type":"number","min":"0","step":"1","rules":_vm.rules.required,"outlined":"","dense":""},model:{value:(_vm.formData.amountCharged),callback:function ($$v) {_vm.$set(_vm.formData, "amountCharged", _vm._n($$v))},expression:"formData.amountCharged"}}),_c(VTextField,{attrs:{"label":"Trip Distance in Km","min":"0","step":"1","outlined":"","dense":""},model:{value:(_vm.formData.distanceTraveled),callback:function ($$v) {_vm.$set(_vm.formData, "distanceTraveled", _vm._n($$v))},expression:"formData.distanceTraveled"}}),_c(VFileInput,{ref:"file",attrs:{"accept":"image/jpeg,image/png,application/pdf","rules":_vm.rules.required,"prepend-icon":"","append-icon":"mdi-file","dense":"","outlined":"","label":"Upload Document"},on:{"change":_vm.uploadFile}})],1),_c(VDivider)],1),_c(VCardActions,{staticClass:"pb-7 mr-6 mt-n3"},[_c(VSpacer),_c(VBtn,{staticClass:"mx-3",attrs:{"color":"error","text":""},on:{"click":function($event){_vm.setDialog = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.accountForCabRequest()}}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }