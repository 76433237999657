import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VAppBar,{staticClass:"leaveBar mb-2",staticStyle:{"background-color":"#f0f3f4"},style:({ border: '0' }),attrs:{"dense":""}},[_c('div',{staticClass:"text-lg-h5",staticStyle:{"font-weight":"300"}},[_vm._v("Cab Requests")]),_c(VSpacer),_c(VBreadcrumbs,{attrs:{"customDivider":"","divider":"/","large":"","items":_vm.path}})],1),_c(VRow,{staticClass:"statistic-cards mt-n4"},[_c(VCol,{staticClass:"pb-2",attrs:{"lg":"3","cols":"sm"}},[_c(VHover,{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'statistic-cards-hover': hover },staticStyle:{"border-left":"var(--v-primary-base) solid"},attrs:{"elevation":hover ? 5 : 0},on:{"click":function($event){_vm.status = 'All'}}},[_c(VRow,{staticClass:"no-gutters"},[_c('div',{staticClass:"col-9"},[_c(VRow,[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"primary fill-height"},[_vm._v(" ")])]),_c('div',{staticClass:"col pa-3 py-4 primary--text"},[_c('h5',{staticClass:"text-truncate text-uppercase"},[_vm._v("ALL")]),_c('h1',[_vm._v(_vm._s(_vm.total))])])])],1),_c('div',{staticClass:"col-3"},[_c(VCardActions,[_c(VAvatar,{attrs:{"color":"primary lighten-4","size":"50"}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-cash")])],1)],1)],1)])],1)]}}])})],1),_c(VCol,{staticClass:"pb-2",attrs:{"lg":"3","cols":"sm"}},[_c(VHover,{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'statistic-cards-hover': hover },staticStyle:{"border-left":"var(--v-primary-base) solid"},attrs:{"elevation":hover ? 5 : 0},on:{"click":function($event){_vm.status = 'Open'}}},[_c(VRow,{staticClass:"no-gutters"},[_c('div',{staticClass:"col-9"},[_c(VRow,[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"primary fill-height"},[_vm._v(" ")])]),_c('div',{staticClass:"col pa-3 py-4 primary--text"},[_c('h5',{staticClass:"text-truncate text-uppercase"},[_vm._v("OPEN")]),_c('h1',[_vm._v(_vm._s(_vm.countOpenRequests))])])])],1),_c('div',{staticClass:"col-3"},[_c(VCardActions,[_c(VAvatar,{attrs:{"color":"primary lighten-4","size":"50"}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-timelapse")])],1)],1)],1)])],1)]}}])})],1),_c(VCol,{staticClass:"pb-2",attrs:{"lg":"3","cols":"sm"}},[_c(VHover,{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'statistic-cards-hover': hover },staticStyle:{"border-left":"var(--v-primary-base) solid"},attrs:{"elevation":hover ? 5 : 0},on:{"click":function($event){_vm.status = 'Pending Approval'}}},[_c(VRow,{staticClass:"no-gutters"},[_c('div',{staticClass:"col-9"},[_c(VRow,[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"primary fill-height"},[_vm._v(" ")])]),_c('div',{staticClass:"col pa-3 py-4 primary--text"},[_c('h5',{staticClass:"text-truncate text-uppercase"},[_vm._v("PENDING")]),_c('h1',[_vm._v(_vm._s(_vm.countPendingRequests))])])])],1),_c('div',{staticClass:"col-3"},[_c(VCardActions,[_c(VAvatar,{attrs:{"color":"primary lighten-4","size":"50"}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-timelapse")])],1)],1)],1)])],1)]}}])})],1),_c(VCol,{staticClass:"pb-2",attrs:{"lg":"3","cols":"sm"}},[_c(VHover,{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'statistic-cards-hover': hover },staticStyle:{"border-left":"var(--v-primary-base) solid"},attrs:{"elevation":hover ? 5 : 0},on:{"click":function($event){(_vm.status = 'Open'), (_vm.status = 'Approved')}}},[_c(VRow,{staticClass:"no-gutters"},[_c('div',{staticClass:"col-9"},[_c(VRow,[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"primary fill-height"},[_vm._v(" ")])]),_c('div',{staticClass:"col pa-3 py-4 primary--text"},[_c('h5',{staticClass:"text-truncate text-uppercase"},[_vm._v("ACCOUNTED")]),_c('h1',[_vm._v(_vm._s(_vm.countAccountedRequest))])])])],1),_c('div',{staticClass:"col-3"},[_c(VCardActions,[_c(VAvatar,{attrs:{"color":"primary lighten-4","size":"50"}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-star-circle")])],1)],1)],1)])],1)]}}])})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mt-3",attrs:{"tile":"","elevation":"0"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"top-bar mb-2"},[_c('h3',{staticClass:"primary--text text-lg-h5 mr-2"},[_vm._v("Cab Requests")]),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"mx-2",attrs:{"prepend-inner-icon":"filter_alt","color":"primary","dense":"","single-line":"","hide-details":"","label":"Filter by date"},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.filterDatePicker),callback:function ($$v) {_vm.filterDatePicker=$$v},expression:"filterDatePicker"}},[_c(VDatePicker,{attrs:{"range":""},on:{"input":function($event){(_vm.filterDatePicker = false),
                    (_vm.startDate = _vm.filterDate[0]),
                    (_vm.endDate = _vm.filterDate[1])},"click":function($event){(_vm.status = 'Open'), (_vm.status = 'Issued')}},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1),_c(VSpacer),_c(VBtn,{attrs:{"to":{ name: 'LittleCabCard' },"color":"primary"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Request ")],1)],1),_c(VCardText,[_c(VDataTable,{staticClass:"table-font table-striped",attrs:{"headers":_vm.headers,"items":_vm.cabRequests,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":5,"fixed-header":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.postingDate",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(_vm._f("formatDate")(item.postingDate)))])]}},{key:"item.travelDate",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(_vm._s(_vm._f("formatDate")(item.travelDate)))])]}},{key:"item.distanceTraveled",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(" "+_vm._s(_vm._f("number")(item.distanceTraveled))+"KM")])]}},{key:"item.estimateAmount",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.estimateAmount)))])]}},{key:"item.amountCharged",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"table-font"},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.amountCharged)))])]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [_c(VIcon,{attrs:{"small":"","left":"","color":item.status === 'Approved' && item.status === 'Open'
                      ? _vm.getColor('Review').color
                      : _vm.getColor(item.status).color}},[_vm._v("mdi-radiobox-marked")]),_c('span',{attrs:{"small":"","lowercase":"","text-color":"white"}},[(item.status === 'Open')?_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.status))]):(item.status === 'Released')?_c('span',{staticClass:"text-lowercase"},[_vm._v("approved")]):_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(item.status)))])])]}},{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1 text--white text-capitalize",attrs:{"color":"success","label":"","small":"","to":{
                          name: 'LittleCabCard',
                          params: { code: item.no },
                        }}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"small":"","left":"","color":"white"}},[_vm._v("mdi-eye")]),_vm._v(" view ")],1)]}}],null,true)},[_c('span',[_vm._v("view Details")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.canRequestApproval(item)),expression:"canRequestApproval(item)"}],staticClass:"mx-1 text--white text-capitalize",attrs:{"color":"success","label":"","small":""},on:{"click":function($event){return _vm.requestApproval(item.no)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":"","left":"","color":"white"}},[_vm._v("mdi-check")]),_vm._v(" Request Approval ")],1)]}}],null,true)},[_c('span',[_vm._v("Request Approval")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Pending Approval'),expression:"item.status === 'Pending Approval'"}],staticClass:"mx-1",attrs:{"color":"orange lighten-4","label":"","ripple":"","small":""},on:{"click":function($event){return _vm.cancelCabApprovalRequest(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"orange darken-4","small":""}},[_vm._v("mdi-timer-cancel-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("cancel cab request")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Open'),expression:"item.status === 'Open'"}],staticClass:"mx-1",attrs:{"color":"red lighten-4","label":"","small":""},on:{"click":function($event){return _vm.deleteCabRequest(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"red darken-4","small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove this request")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Released'),expression:"item.status === 'Released'"}],staticClass:"mx-1 white--text text-capitalize",attrs:{"color":"primary","small":"","elevation":"0"},on:{"click":function($event){return _vm.accountForCabRequest(item.no)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-cash")]),_vm._v(" account ")],1)]}}],null,true)},[_c('span',[_vm._v("account")])])],1)]}}])})],1)],1)],1)],1)],1),_c('little-cab-account-request-dialog',{attrs:{"dialog":_vm.dialog,"request-no":_vm.selectedRequestNo},on:{"close-dialog":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }