<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-form v-model="isValid" ref="CabAccountForm">
        <v-card-title>Account For cab Request</v-card-title>
        <v-card-text>
          <div class="mx-5 mt-5">
            <v-text-field
              v-model.number="formData.amountCharged"
              label="Trip Cost"
              type="number"
              min="0"
              step="1"
              :rules="rules.required"
              outlined
              dense
            />
            <v-text-field
              v-model.number="formData.distanceTraveled"
              label="Trip Distance in Km"
              min="0"
              step="1"
              outlined
              dense
            />
            <v-file-input
              ref="file"
              accept="image/jpeg,image/png,application/pdf"
              @change="uploadFile"
              :rules="rules.required"
              prepend-icon=""
              append-icon="mdi-file"
              dense
              outlined
              label="Upload Document"
            />
          </div>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions class="pb-7 mr-6 mt-n3">
          <v-spacer />
          <v-btn class="mx-3" v-on:click="setDialog = false" color="error" text>
            Cancel
          </v-btn>
          <v-btn @click="accountForCabRequest()" color="primary">
            Submit
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
export default {
  name: "LittleCabAccountRequestDialog",
  props: {
    dialog: Boolean,
    requestNo: Number,
  },

  data: function () {
    return {
      isValid: false,
      formData: {
        attachments: [],
        distanceTraveled: 0,
        amountCharged: null,
      },
    };
  },

  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    rules() {
      return {
        required: [(v) => !!v || "field is required"],
      };
    },
  },

  methods: {
    async uploadFile(e) {
      const file = e;
      console.log("files", file);
      if (file) {
        let base64File = await helpers.toBase64(file);
        base64File = base64File.split(",")[1];
        this.formData.attachments.push({
          attachment: base64File,
          fileName: file.name,
        });
      }
    },
    accountForCabRequest() {
      if (!this.isValid) {
        this.$refs.CabAccountForm.validate();
      } else {
        this.formData.no = this.requestNo;
        this.$store.dispatch("littlecab/accountForCabRequest", {
          ...this.formData,
        });
        this.$refs.CabAccountForm.reset();
        this.setDialog = false;
      }
    },
  },
};
</script>

<style scoped></style>
